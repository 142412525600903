// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-callback-js": () => import("./../src/pages/auth_callback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oops-js": () => import("./../src/pages/oops.js" /* webpackChunkName: "component---src-pages-oops-js" */),
  "component---src-pages-reports-js": () => import("./../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */)
}

