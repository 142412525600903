import auth0 from "auth0-js"
import { navigate } from "gatsby"
import AWS from "aws-sdk"

const LOCAL_STORAGE_LOGGED_IN_KEY = "isLoggedIn"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser ? new auth0.WebAuth({
  domain: process.env.AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENTID,
  redirectUri: process.env.AUTH0_CALLBACK,
  responseType: "token id_token",
  scope: "openid"
}) : {}

function onAuthError(err) {
  console.error(err)
  localStorage.removeItem(LOCAL_STORAGE_LOGGED_IN_KEY)
  navigate("/oops")
}

function onAuthSuccess() {
  navigate("/")
}

function setSession(err, authResult) {
  return new Promise((resolve, reject) => {
    if (err)
      return reject(err)
    if (!(authResult && authResult.accessToken && authResult.idToken))
      return reject(`Auth result not set after authentication: ${authResult}`)

    AWS.config.region = 'us-east-1';
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
      Logins: { [process.env.COGNITO_LOGIN_ID]: authResult.idToken }
    });

    AWS.config.credentials.get(err => {
      if (err) return reject(err)

      localStorage.setItem(LOCAL_STORAGE_LOGGED_IN_KEY, true)
      resolve()
    })
  })
}

export const isAuthenticated = () => {
  if (!isBrowser) return
  return localStorage.getItem(LOCAL_STORAGE_LOGGED_IN_KEY) === "true"
}

// If not authenticated, redirects user to Auth0 sign-in
export const login = () => {
  if (!isBrowser) return
  auth.authorize({ connection: process.env.AUTH0_CONNECTION })
}

// Called by auth_callback after authentication returns from Auth0
export const handleAuthentication = () => {
  if (!isBrowser) return
  auth.parseHash((err, authResult) => setSession(err, authResult).then(onAuthSuccess).catch(onAuthError))
}

// Runs in componentDidMount phase of the SessionCheck component which wraps the root element as defined in gatsby-browser.js
export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, (err, authResult) => setSession(err, authResult).then(onAuthSuccess).catch(onAuthError).finally(callback))
}